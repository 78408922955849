



















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import SignUpButton from '@/components/common/SignUpButton.vue'
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    SignUpButton
  }
})
export default class SplashScreen extends Vue {
  get preferMobileHintVisible (): boolean {
    return !this.$device.mobile
  }

  private created (): void {
    // var encode = btoa('123')
    // console.log(atob(encode))
    localStorage.setItem('testResult', '')
    localStorage.setItem('isAuth', 'false')
    localStorage.setItem('newEmail', '')
    localStorage.removeItem('prevRoute')
    this.$store.dispatch('auth/fetchGeolocation')
    localStorage.setItem('convId', '')
  }

  private mounted (): void {
    sessionStorage.clear()
    if (window.innerWidth < 767) {
      this.$root.$emit('logo', true)
    }
  }

  private destroyed () {
    if (window.innerWidth < 767) {
      this.$root.$emit('logo', false)
    }
  }
}
